import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import GlobalCSS from './global-css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        siteYaml {
          siteMetaData {
            title
            legal_title
            description
            author
            author_title
            lang
            copyright
            keywords
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalCSS />
        <Header siteTitle={data.siteYaml.siteMetaData.title} />
        {children}
        <Footer siteData={data.siteYaml.siteMetaData} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
