import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'

const Footer = ({ siteData }) => {
  return (
    <footer>
      <strong>
        &copy; {siteData.copyright} - {siteData.legal_title}
      </strong>
      <div>
        <small>
          <a
            href={siteData.author}
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              color: black;
              &:hover {
                color: #0190b6;
              }
            `}
          >
            {siteData.author_title}
          </a>
        </small>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteData: PropTypes.object,
}

export default Footer
