import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Global, css } from '@emotion/core'
import { rhythm } from '../utils/typography'
import Image from 'gatsby-image'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "backgnd.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Global
            styles={css`
              * {
                box-sizing: border-box;
              }
              body {
                background: #bbc3c4;
                text-align: center;
              }
              main,
              footer {
                margin: 0 auto;
                max-width: 960px;
              }
              footer {
                padding: ${rhythm(3 / 2)};
              }
            `}
          />
          <Image
            fluid={data.desktop.childImageSharp.fluid}
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              zIndex: '-100',
            }}
          />
        </>
      )
    }}
  />
)
