import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO ({ description, lang, meta, keywords, title }) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription = data.siteYaml.siteMetaData.description;
				return (
					<Helmet
						htmlAttributes={{
							lang: data.siteYaml.siteMetaData.lang
						}}
						title={data.siteYaml.siteMetaData.title}
						meta={[
							{
								name: `description`,
								content: metaDescription
							},
							{
								property: `og:title`,
								content: data.siteYaml.siteMetaData.title
							},
							{
								property: `og:description`,
								content: metaDescription
							},
							{
								property: `og:type`,
								content: `website`
							},
							{
								property: 'og:locale',
								content: data.siteYaml.siteMetaData.locale
							},
							{
								property: 'og:site_name',
								content: 'Reactgo'
							},
							{
								name: 'robots',
								content: 'index, follow'
							},
							{
								name: `twitter:card`,
								content: `summary`
							},
							{
								name: `twitter:creator`,
								content: data.siteYaml.siteMetaData.twitter
							},
							{
								name: `twitter:title`,
								content: data.siteYaml.siteMetaData.title
							},
							{
								name: `twitter:description`,
								content: metaDescription
							},
							{
								name: `keywords`,
								content: data.siteYaml.siteMetaData.keywords
							}
						]}
					/>
				);
			}}
		/>
	);
}

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		siteYaml {
			siteMetaData {
				title
				description
				author
				author_title
				twitter
				lang
				copyright
				keywords
			}
		}
	}
`;
