import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'

const StyledLink = ({ to, where, color, hover }) => {
  // some bullshit about using Link on internal url's only
  if (/^\/(?!\/)/.test(to)) {
    return (
      <Link
        to={to}
        css={css`
          color: ${color};
          padding-left: ${rhythm(1 / 6)};
          &: hover {
            color: ${hover};
            font-weight: bold;
          }
        `}
      >
        {' '}
        {where}
      </Link>
    )
  }

  return (
    <a
      href={to}
      css={css`
        color: ${color};
        padding-left: ${rhythm(1 / 6)};
        &: hover {
          color: ${hover};
          font-weight: bold;
        }
      `}
    >
      {' '}
      {where}
    </a>
  )
}

StyledLink.propTypes = {
  to: PropTypes.string,
  where: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.string,
}

StyledLink.defaultProps = {
  to: '',
  where: '',
  color: 'white',
  hover: '#0190b6',
}

export default StyledLink
