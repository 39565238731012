import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'
import Logo from './logo'
import StyledLink from './styled-link'
import StyledTitle from './styled-title'

const Header = ({ siteTitle }) => (
  <div
    css={css`
      background: #0e4a5a;
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-decoration: none;
        font-weight: normal;
        margin: 0 auto;
        max-width: 960;
        padding: ${rhythm(1)};
        @media only screen and (max-width: 732px) {
          padding: ${rhythm(1 / 2)};
        }
      `}
    >
      <Logo />
      <StyledTitle to={'/'} where={siteTitle} />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
        `}
      >
        <StyledLink to={'/'} where={'Home |'} />
        <StyledLink to={'/services'} where={'Our Services |'} />
        <StyledLink to={'/contact'} where={'Contact'} />
      </div>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
