import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'

const StyledTitle = ({ to, where }) => (
  <h1
    css={css`
      margin: 0;
      padding-right: ${rhythm(1 / 2)};
    `}
  >
    <Link
      to={to}
      css={css`
        color: #0190b6;
        &:hover {
          color: white;
        }
      `}
    >
      {' '}
      {where}
    </Link>
  </h1>
)

StyledTitle.propTypes = {
  to: PropTypes.string,
  where: PropTypes.string,
}

StyledTitle.defaultProps = {
  to: '',
  where: '',
}

export default StyledTitle
